<template src='./template.html'>
  
</template>

<script>
import qs from 'qs'


export default {
    data(){
        return{
            password_eye:false,//密碼顯示
            info:null,
            keep_login:false,
            user:{
                account:null,
                password:null,
                isdealer:1,
            }
        }
    },
    props:[
        'closeDearlerLogin'
    ],
    mounted(){
        this.$refs.input_phone.focus()
    },
    methods:{
        Login(){
            let vm = this
            console.log('Login')
            if(this.user.account != null & this.user.password != null){
                this.axios.post('/api/member/login',qs.stringify({
                    lang: this.$store.state.lang ,
                    account: this.user.account ,
                    password: this.user.password,
                    isdealer: this.user.isdealer,
                }))
                .then(function(response){
                    console.log(response.data.status)
                    if(response.data.status == true){
                        vm.info =response,
                        vm.loadingMemberdata(response)
                        vm.getShopCar()
                        vm.$store.commit('closeDearlerLogin')
                        vm.$store.commit('mbCloseMenuActive')
                        // vm.$router.push('/memberCenter')
                    }
                    alert(response.data.msg)
                } )
                .catch(function (error){
                    console.log(error)
                })
            }
            else{
                alert('帳號密碼不可為空')
            }

        },
        //get shopcar
        getShopCar(){
            let vm =  this
            vm.$store.commit('openOverlay')
            vm.axios.post('/api/car/show_car', qs.stringify({
                lang: vm.$store.state.lang,
                isdealer: vm.$session.get('isdealer'),
            }))
            .then(function(response)  {
                if(response.data.car){
                    vm.$session.set('shopcar',response.data.car),
                    vm.$store.commit('shopcarItem',response.data.car.item)
                }
            })
            .catch(err => (
                alert(err)
            ))
        },
        getProfile() {
            let vm = this
            window.FB.api("/me?fields=name,id,email,birthday,last_name,first_name", function(res) {
                if(res.error){
                    console.log(res.error)
                }
                else{
                    vm.axios.post('/api/member/fb_login',qs.stringify({
                        lang: vm.$store.state.lang,
                        id:res.id,
                        first_name:res.first_name,
                        last_name:res.last_name,
                        email:res.email,
                        birthday:res.birthday,
                    }),
                    )
                    .then(response => (
                        vm.info=response,
                        vm.loadingMemberdata(response),
                        vm.closeDearlerLogin()
                    ))
                    .catch(function (error){
                        alert('會員登入失敗')
                        console.log(error)
                    })
                }
            })
        },
        mbCloseMenuActive(){   
            this.closeDearlerLogin()
            this.$store.commit('mbCloseMenuActive')
            document.querySelector('body').setAttribute('style', 'overflow:visible;height:auto')
        },
        loadingMemberdata(response){
            console.log(response.data)
            let vm = this
            if(vm.keep_login === true){
                this.$store.commit('keepLogin')
                this.$store.commit('memberIsdealer',1),
                this.$store.commit('memberPhone',response.data.member_data.phone)
                this.$store.commit('memberName',response.data.member_data.name)
                this.$store.commit('memberAccount',response.data.member_data.account)
                this.$store.commit('memberUserId',response.data.member_data.userid)
                this.$store.commit('memberTel',response.data.member_data.tel)
                this.$store.commit('memberNumbering',response.data.member_data.numbering)
                this.$store.commit('memberSex',response.data.member_data.sex)
                this.$store.commit('memberEmail',response.data.member_data.email)
                this.$store.commit('memberBirthday',response.data.member_data.birthday)
                this.$store.commit('memberCity',response.data.member_data.city)
                this.$store.commit('memberTown',response.data.member_data.town)
                this.$store.commit('memberAddress',response.data.member_data.address)
            }
            this.$store.commit('memberLogin')
            this.$session.set('isdealer',1),
            this.$session.set('memberLogin',true),
            this.$session.set('memberPhone',response.data.member_data.phone),
            this.$session.set('memberName',response.data.member_data.name),
            this.$session.set('memberAccount',response.data.member_data.account)
            ,
            this.$session.set('memberId',response.data.member_data.userid)
            ,
            this.$session.set('memberTel',response.data.member_data.tel)
            ,
            this.$session.set('memberNumbering',response.data.member_data.numbering)
            ,
            this.$session.set('memberSex',response.data.member_data.sex)
            ,
            this.$session.set('memberEmail',response.data.member_data.email)
            ,
            this.$session.set('memberBirthday',response.data.member_data.birthday)
            ,
            this.$session.set('memberCity',response.data.member_data.city)
            ,
            this.$session.set('memberTown',response.data.member_data.town)
            ,
            this.$session.set('memberAddress',response.data.member_data.address)
            window.location.reload()
        },
        //密碼顯示status
        eyeStatus(){
            this.password_eye = !this.password_eye
        }
    }
}
</script>

<style>

</style>