<template>
  <div id="app">
    <v-app >
      <Navbar :activeLogin="activeLogin" :activeDearlerLogin="activeDearlerLogin" />
      <v-overlay :value="this.$store.state.overlay" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-fade-transition>
        <Login ref="login" :closeLogin="closeLogin" v-if="this.$store.state.active_login" />
      </v-fade-transition>
      <v-fade-transition>
        <DearlerLogin ref="login" :closeDearlerLogin="closeDearlerLogin" v-if="this.$store.state.active_dearler_login" />
      </v-fade-transition>
      <router-view style="min-height:1000px;" 
      :key="$route.fullPath"  
      :closeLogin="closeLogin" :closeDearlerLogin="closeDearlerLogin"/>
      <Footer />
    </v-app>  
  </div>
</template>


<script>
import './assets/css/app.scss' 
import Navbar from './components/navbar/index.vue'
import Footer from './components/footer/index.vue'
import Login from './components/tw_login/index.vue'
import DearlerLogin from './components/tw_dearlerLogin/index.vue'

export default {
  name: 'Home',
  data(){
    return{
      active_login: false,
    }
  },
  components: {
    Navbar,
    Footer,
    Login,
    DearlerLogin
  },
  created(){
    if(!this.$session.get('token')){
      this.$store.commit('getToken')
    }
    const isLogin = this.$session.get('memberLogin')
    if(!isLogin){
      if (this.$store.state.member.keep_login){
          this.$store.commit('memberLogin')
          this.$session.set('memberLogin', true),
          this.$session.set('isdealer',this.$store.state.member.isdealer)
          this.$session.set('memberName', this.$store.state.member.name),
          this.$session.set('memberAccount', this.$store.state.member.account),
          this.$session.set('memberId', this.$store.state.member.userid),
        
          this.$session.set('memberTel', this.$store.state.member.tel),
        
          this.$session.set('memberNumbering', this.$store.state.member.numbering),
        
          this.$session.set('memberSex', this.$store.state.member.sex),
        
          this.$session.set('memberEmail', this.$store.state.member.email),
        
          this.$session.set('memberBirthday', this.$store.state.member.birthday),
        
          this.$session.set('memberCity', this.$store.state.member.city),
        
          this.$session.set('memberTown', this.$store.state.member.town),
        
          this.$session.set('memberAddress', this.$store.state.member.address)
      }
      else{
        this.$store.commit('shopcarItem', 0)
      }
    }
  },
  updated(){
    this.goTop()
  },
  mounted(){
    this.closeDearlerLogin()
    console.log(this.$store.state.name)
  },
  methods:{
    beforRouterEnter(){

    },
    activeLogin(){
      this.$store.commit('activeLogin')

    },
    closeLogin(){
      this.$store.commit('closeLogin')

    },
    activeDearlerLogin(){
      this.$store.commit('activeDearlerLogin')

    },
    closeDearlerLogin(){
      this.$store.commit('closeDearlerLogin')

    },
    goTop(){
        document.documentElement.scrollTop = 0;
    },
  }

}
</script>
