<template src='./template.html'>
  
</template>

<script>
import qs from 'qs'
export default {
    data(){
        return{
            pc : true,
            screenWidth: document.body.clientWidth,
            rotate:[false,false,false],
            add_item:null,
            //soical_link
            soical_link:null,
            footer: [
                {
                    id:0,
                    show:'false'
                },
                {
                    id:1,
                    show:'false'
                },
                {
                    id:2,
                    show:'false'
                },
                {
                    id:3,
                    show:'false'
                }
            ]
        }
    },
    watch: {
    screenWidth (val) {
        this.screenWidth = val
        if(this.screenWidth <= 992){
            this.pc = false
            }
            else{
                this.pc = true
            }
    }
    },
    created(){
        console.log(123)
        var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if(width<=992){
            console.log('mobile')
            this.pc = false
        }
    },
    mounted(){
        const vm = this
        vm.getTitleApi()
        this.getApiSoical()
        window.onresize = () => {
            return (() => {
            window.screenWidth = document.body.clientWidth
            vm.screenWidth = window.screenWidth
            })()
        }
        for(let i=0 ; i < this.footer.length ; i++){
            console.log(i)
            this.openContent(i)
        }
    },
    methods:{
        goSubView(){
            this.$router.push({path: '/subview'})
        },
        openContent(index){
            console.log(this.footer[index].show)
            this.rotate[index] =! this.rotate[index]
            this.footer[index].show =! this.footer[index].show
        },
        getTitleApi(){
            this.axios.post('/api/custompage/gettitle',qs.stringify({
                lang: this.$store.state.lang
            }))
            .then(response => (
                this.add_item = response.data.result
            ))
        },

        //API杜群軟體連結
        getApiSoical(){
            this.axios.post('/api/index/get_footer_link')
            .then(response => (
                console.log(response.data),
                this.soical_link = response.data
            ))
        }
    }
}
</script>

<style>

</style>