<template>
  <div class="home">
    <Fixedshop/>
    <Swiper />
    <div class="section1" v-if="special_content">
      <div class="top"
      v-html="special_content">
      </div>
      <!-- <div class="bottom">
        <h2>EXUSTAR   PERFORMANCE  IN  MOTION </h2>
        <p>御風展翼的淬鍊戰靴隆重登場，帶來不同以往的舒適體驗。</p>
        <div class="button-container">
          <button @click="goRouter('202106220001','202106220001')">
            選購自行車系列
          </button>
          <button @click="goRouter('202106220002','202107090005')">
            選購摩托車系列
          </button>
        </div>
      </div> -->
    </div>
    <template v-if="banner_2_list">
      <template
      v-for="item in banner_2_list" >
        <div class="section4 w-100 mt-100"
        :key="item.index">
          <div class="container">
            <img :src="'api/'+item.pic" alt="">
            <div class="content">
              <p>{{ item.summary }}</p>
              <h4>{{item.title}}</h4>
              <a :href="item.link" v-if="item.link">更多</a>
            </div>
          </div>
        </div>
      </template>
    </template>
    <Youtube />
    <template v-if="index_pic">
      <div class="section3">
        <h3>流行趨勢</h3>
        <div class="container">
            <div class="content" >
                <img :src="'api/'+index_pic[1].pic" alt="">
              <div class="sub-content">
                <p>{{index_pic[1].title}}</p>
                <a :href="index_pic[1].link" v-if="index_pic[1].link">更多</a>
              </div>
            </div>
            <div class="content">
              <template v-if="index_pic">
                <img :src="'api/'+index_pic[2].pic" alt="">
              </template>
              <div class="sub-content">
                <p>{{index_pic[2].title}}</p>
                <a :href="index_pic[2].link" v-if="index_pic[3].link">更多</a>
              </div>
            </div>
        </div>
      </div>
      <div class="section4">
        <h3>精選商品</h3>
        <div class="container">
          <!-- <img src='../../public/img/product-big.jpg' alt=""> -->
          <template v-if="index_pic">
            <img :src="'api/'+index_pic[3].pic" alt="">
          </template>
          <div class="content">
            <p>新品到貨</p>
            <h4>{{index_pic[3].title}}</h4>
            <a :href="index_pic[3].link" v-if="index_pic[3].link">更多</a>
          </div>
        </div>
      </div>
    </template>
    <template v-if="products_card != null">
      <Products :products_card="products_card"/>
    </template>
  </div>
</template>

<script>
import Swiper from '../components/swiper/index.vue'
import Products from '../components/products-car/index.vue'
import Fixedshop from '../components/fixedshop/index.vue'
import Youtube from '../components/youtube/index.vue'
import qs from 'qs'
export default {
  name: 'Home',
  data(){
    return{
      index_pic:null,
      products_card:null,
      banner_2_list:[],
      special_content:``,
    }
  },
  components: {
    Swiper,
    Products,
    Fixedshop,
    Youtube,
  },
  created(){
    this.$store.commit('openOverlay')
    this.axios.post('/api/directions/get_fkind',qs.stringify({
      lang:this.$store.state.lang
    }))
    .then(response => (
      console.log(response),
      this.products_card = response.data.result,
      this.getIndexPic()
    ))

  },
  beforeCreate(){
  },
  mounted(){
    this.getBanner2()
    this.getBannerContent()
  },
  methods:{
    goRouter(fid,sid){
      this.$router.push({name:`ShoppingCenter`,
      params:{
        fid: fid,
        sid: sid
      }})
    },
    getIndexPic(){
      this.axios.post('/api/directions/get_pic',qs.stringify({
        lang: this.$store.state.lang
      }))
      .then(response => (
        this.index_pic = response.data.data,
        console.log(response),
        this.$store.commit('closeOverlay')
      ))
    },
    getBanner2(){
        console.log('getBanner2'),
        this.axios.post('/api/directions/get_banner2', qs.stringify({
        lang: this.$store.state.lang
      }))
      .then(response => (
        console.log('getBanner2_succes'),
        console.log(response),
        this.banner_2_list = response.data.result
      ))
    },
    getBannerContent(){
      let vm = this
        console.log('getBannerContent'),
        this.axios.post('/api/directions/get_banner_content', qs.stringify({
        lang: this.$store.state.lang
      }))
      .then(response => (
        console.log('getBannerContent_success'),
        console.log(response),
        vm.special_content = response.data.data.banner_content
      ))

    }
  }
}
</script>
