import Vue from 'vue'
import Vuex from 'vuex'
import qs from 'qs'
import createPersistedState from 'vuex-persistedstate';
// import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang:'tw',                  //語系
    active_login:false,         //會員登入
    active_dearler_login:false, //經銷商登入
    overlay:true,               //遮罩層啟動
    shopcar_item:null,          //購物車產品數量
    mb_menu:{                   //側邊選單展開
      mb_active:false,
    },
    login: false,             //是否已登入
    member:{                    //會員資料
      isdealer: null,            //是否經銷商
      phone:null,                //經銷商行動電話
      keep_login: false,         //保持登入
      name: null,               //名字
      account: null,            //帳號
      numbering: null,          //編號
      userid: null,             //ID
      tel: null,                //電話
      sex: null,                //性別
      email: null,              //mail
      birthday: null,           //生日
      city: null,               //城市
      town:null,                //區域
      address:null              //路
    }
  },
  //永久儲存資料
  plugins: [createPersistedState({
    paths: [
      'member',
      'shopcar_item',
    ]
  })],
  mutations: {
    //重製會員資料
    Reset(state){
      state.member = {
        login: false,
        name: null,
        account: null,
        numbering: null,
        userid: null,
        tel: null,
        sex: null,
        email: null,
        birthday: null,
        city: null,
        town: null,
        address: null
      }
      state.keep_login = false
      state.shopcar= null,               
      state.shopcar_item= null         
    },
    //會員登入
    activeLogin(state){
      state.active_login = true
      document.querySelector('body').setAttribute('style', 'overflow:hidden;height:100vh',)
    },
    closeLogin(state){
      state.active_login = false
      document.querySelector('body').setAttribute('style', 'overflow:visible;height:auto')
    },
    //經銷商登入
    activeDearlerLogin(state) {
      state.active_dearler_login = true
      document.querySelector('body').setAttribute('style', 'overflow:hidden;height:100vh',)
    },
    closeDearlerLogin(state) {
      state.active_dearler_login = false
      document.querySelector('body').setAttribute('style', 'overflow:visible;height:auto')
    },
    //載入
    openOverlay(state) {
      state.overlay = true
      document.querySelector('body').setAttribute('style', 'overflow:hidden;height:100vh',)
    },
    closeOverlay(state){
      state.overlay = false
      document.querySelector('body').setAttribute('style', 'overflow:visible;height:auto')
    },

    bodyOverHidden() {
      document.querySelector('body').setAttribute('style', 'overflow:hidden;height:100vh',)
    },
    bodyOverViseibel(){
      document.querySelector('body').setAttribute('style', 'overflow:visible;height:auto')
    },
    //menu
    mbMenuActive(state) {
      state.mb_menu.mb_active = true
      document.querySelector('body').setAttribute('style', 'overflow:hidden;height:100vh',)
    },
    mbCloseMenuActive(state) {
      state.mb_menu.mb_active = false
      document.querySelector('body').setAttribute('style', 'overflow:visible;height:auto')
    },
    //拿取token
    getToken() {
      this.axios.post('/api/index/get_token', qs.stringify({
        account: Math.random().toString(36).substr(2, 8)
      }))
        .then(res => (
          this.$session.set('token', res.data.token),
          this.$router.go(0),
          console.log(this.$session.get('token'))
        ))
    },
    //購物車數量
    shopcarItem(state,data){
      state.shopcar_item = data
    },
    memberLogin(state) {
      state.login = true
    },
    memberOut(state) {
      state.login = false
    },
    //永久儲存
    keepLogin(state){
      state.member.keep_login = !state.member.keep_login
    },
    memberName(state,name){
      state.member.name = name
    },
    memberAccount(state, account) {
      state.member.account = account
    },
    memberUserId(state, data){
      state.member.userid = data
    },
    memberNumbering(state, data) {
      state.member.numbering = data
    },
    memberTel(state, data) {
      state.member.tel = data
    },
    memberSex(state, data) {
      state.member.sex = data
    },
    memberEmail(state, data) {
      state.member.email = data
    },
    memberBirthday(state, data) {
      state.member.birthday = data
    },
    memberCity(state, data) {
      state.member.city = data
    },
    memberTown(state, data) {
      state.member.town = data
    },
    memberAddress(state, data) {
      state.member.address = data
    },
    memberIsdealer(state, data) {
      state.member.isdealer = data
    },
    memberPhone(state, data) {
      state.member.phone = data
    },
  },
  actions: {
  },
  modules: {
  }
})
