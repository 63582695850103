<template src='./template.html'>
  
</template>

<script>

export default {
    data(){
        return{
            car_product:0,
            shop_car:null
        }
    },
    mounted(){
        this.goTop()
        if(this.$session.get('shopcar')){
            this.shop_car = this.$session.get('shopcar')
            this.car_product = this.shop_car.item
        }
    },
    methods:{
        goTop(){
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            // 实现滚动效果
            let speed = scrollTop / 10 // 每次滚动多少 （步长值）
            const timeTop = setInterval(() => {
                if (document.documentElement.scrollTop !== 0) {
                document.documentElement.scrollTop -= speed // 不在顶部 每次滚动到的位置
                } else {
                clearInterval(timeTop) // 回到顶部清除定时器
                }
            }, 20)
            // document.documentElement.scrollTop = 0;
        },
        goShop(){
            this.$router.push({name:'Shopcar'})
        }
    }
}
</script>

<style>

</style>