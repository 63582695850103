<template src='./template.html'>
  
</template>

<script>
import qs from 'qs'
export default {
  data(){
    return{
        car_product:0,
        scratch:null,
        active:false,
        activeindex:-1,
        activeindex_content:-1,
        activelist:-1,
        input_active:false,
        sub_content:false,
        sub_sub_content:false,
        hove_1:false,
        hove_2:false,
        hove_3:false,
        hove_4:false,
        hove_5:false,
        mb_menu:{
            mb_active:false,
            mb:false,
        },
        main_data:null,
        soical_link:null,
    }
  },
  created(){
    // window.addEventListener("scroll",this.handleScroll)
  },
  props:[
      'activeLogin',
      'activeDearlerLogin'
  ],
  mounted () {
    this.axios.post('/api/products/get_fkind', qs.stringify({
        lang : this.$store.state.lang,
    }))
    .then(response => (
      console.log(response.data),
      this.main_data = response.data.result
    ))
    .catch(error => (
        console.log(error)
    ))
    this.getApiSoical()

  },
  methods:{
    // handleScroll() {
    //   var scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop;
    //     var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //   if (scrollTop > 45 && width>992) {
    //     this.active = true
    //   } else {
    //     this.active = false
    //   }
    // },
    scratchProduct(){
        if(this.scratch != null){
            this.$router.push({name:`ShoppingScratch` , 
            params: {scratch:this.scratch }})
            this.input_active = false
            this.scratch = null
        }
        else{
          alert('請輸入搜尋字關鍵字')
        }

    },
    inputOpen(){
        this.input_active =! this.input_active
        if(!this.input_active){
          this.$refs.keyword.blur()
        }
        else{
          this.$refs.keyword.focus()
        }
    },
    changActive(toIndex){
        this.activeindex = toIndex
    },
    leaveActive(){
        this.activeindex = -1
    },
    goCollect(){
            this.$router.push({name:`MemberCenter` , 
            params: {id:4 }})
    },
    goShoppingCenter(fid,sid){
      this.$router.push({name:`ShoppingCenter`,
      params:{
        fid: fid ,
        sid: sid
      }})
      this.mbCloseMenuActive()
    },
    memberOut(){
        let vm = this
        window.FB.getLoginStatus(function(response) {
            // 檢查登入狀態
            if (response.status === "connected") {
            // 移除授權
            window.FB.api("/me/permissions", "DELETE", function(res) {
                // 用戶登出
                console.log(res)
                window.FB.logout();
            });
            }
        });
        vm.axios.post('/api/member/logout')
        .then(res => (
            console.log(res),
            vm.$session.destroy(),
            this.$store.commit('Reset'),
            alert('會員已登出'),
            vm.$router.push('/'),
            window.location.reload()
        ))
        .catch(function(error){
          console.log(error)
        })
    },

    //mobile  //.

    mbMenuActive(){   
        this.$store.commit('mbMenuActive')
        document.querySelector('body').setAttribute('style', 'overflow:hidden;height:100vh' ,)
    },
    mbCloseMenuActive(){   
        this.$store.commit('mbCloseMenuActive')
        document.querySelector('body').setAttribute('style', 'overflow:visible;height:auto')
    },
    subContentMove(){
            this.sub_content = true
    },
    closeSubContentMove(){
            this.sub_content = false
    },
    mobileChangActive(toIndex){
        this.activeindex = toIndex
    },
    changList(toIndex){
        this.activelist = toIndex
        this.sub_sub_content = true
    },
    closeSubSubContent(){
        this.sub_sub_content = false
    },
    //API杜群軟體連結
    getApiSoical(){
        this.axios.post('/api/index/get_footer_link')
        .then(response => (
            console.log(response.data),
            this.soical_link = response.data
        ))
    }
  }
}
</script>

<style>

</style>