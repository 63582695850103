<template src='./template.html'>
  
</template>

<script>
import carousel from 'vue-owl-carousel'
import qs from 'qs'
//vue-owl-carousel.js 被修改過 9318行 next price
export default {
    components: { carousel },
    data(){
        return{
            items:4,
        }
    },
    props:[
        'products_card'
    ],
    watch:{
        products_card(){
            console.log(1111111)
        }
    },
    created(){
        var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if(width<=1200){
            this.items = 3
        }
        if(width<=991){
            this.items = 2
        }
        if(width<=600){
            this.items = 1
        }
        this.overlay = false
    },
    methods:{
        test(){
            console.log('test')
        },
        router(mainid){
            this.$router.push({name:`Productview` , 
            params: {mainid:mainid }})
        },
        addInsertCollect(id){
            console.log(1213223123)
            if(this.$session.get('memberLogin')){
                this.axios.post('/api/products/insert_collect', qs.stringify({
                    lang: this.$store.state.lang,
                    p_id: id,
                    m_id: this.$session.get('memberId'),
                    ismain: 1
                }))
                .then(response => (
                    console.log(response),
                    alert(response.data.msg)
                ))
            }
            else{
                alert('請先登入會員')
                this.$store.commit('activeLogin')
            }
        }
    }
}
</script>

<style>

</style>