<template src='./template.html'>
  
</template>

<script>
import carousel from 'vue-owl-carousel'
import qs from 'qs'
//vue-owl-carousel 的js被修改過
export default {
    data(){
        return{
            attrs: {
                boilerplate: false,
            },
            main_data:null,
            lang:this.$store.state.lang
        }
    },
    components: { carousel },
    created(){
        this.getCarouselData()
    },
    methods:{
        getCarouselData(){
            this.axios.post('/api/directions/get_banner', qs.stringify({
                lang: this.$store.state.lang
            }))
            .then(response => (
                console.log(response),
                this.main_data = response.data.result
            ))
        },
        goUrl(url){
            window.location.href=url;
        }
    }

}
</script>
