<template src='./template.html'>
  
</template>

<script>
import qs from 'qs'
export default {
  data(){
    return{
      video_url:null,
    }
  },
  beforeCreate(){

    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api'
    const firstScript = document.getElementsByTagName('script')[0]
    firstScript.parentNode.insertBefore(tag, firstScript)
  },
  created(){
    
  },
  watch:{
    video_url:function(){
        let player
        console.log(player)
        let vm = this
          console.log(vm.video_url);
          if(window.YT){
            player = new window.YT.Player('Player',{
              videoId: vm.video_url,
              playerVars:{ 
                autoplay:0,
                controls:0,
                showinfo:1,
                loop:1,
                playlist:vm.video_url,
                fs:0,
                iv_load_policy:3,
                modestbranding:1,
                listType:0,
                rel:0
              },
              events: {
                onReady: function(e) {
                  e.target.mute();      //播放時靜音
                  e.target.playVideo(); //強制播放(手機才會自動播放，但僅限於Android)
                }
              }
            })
          }
    }
  },
  mounted(){
    this.getVideo()
    // this.playVideo()
    let player
    console.log(player)
    if(window.YT){
      console.log('success page')
      player = new window.YT.Player('Player',{
        videoId: this.$session.get('video_url'),
        playerVars:{ 
          autoplay:0,
          controls:0,
          showinfo:1,
          loop:1,
          playlist: this.$session.get('video_url'),
          fs:0,
          iv_load_policy:3,
          modestbranding:1,
          listType:0,
          rel:0
        },
        events: {
          onReady: function(e) {
            e.target.mute();      //播放時靜音
            e.target.playVideo(); //強制播放(手機才會自動播放，但僅限於Android)
          }
        }
      })
    }
    let vm = this
    window.onYouTubeIframeAPIReady =
    function() {
      if(vm.video_url != null){
        console.log(vm.video_url);
        player = new window.YT.Player('Player',{
          videoId: vm.video_url,
          playerVars:{ 
            playsinline:1,
            autoplay:0,
            controls:0,
            showinfo:1,
            loop:1,
            playlist:vm.video_url,
            fs:0,
            iv_load_policy:3,
            modestbranding:1,
            listType:0,
            rel:0
          },
          events: {
            onReady: function(e) {
              e.target.mute();      //播放時靜音
              e.target.playVideo(); //強制播放(手機才會自動播放，但僅限於Android)
            }
          }
        })
      }
    }
  },
  methods:{
    playVideo(){
      console.log('播放')
      document.querySelector('#Player').play()
      // document.getElementById('Player').play()
    },
    getVideo(){
      console.log('youtube')
      this.axios.post('/api/directions/get_video' ,qs.stringify({
        lang: this.$store.state.lang
      }))
      .then(response => (
        console.log(response),
        this.video_url = response.data.data.url,
        console.log(this.video_url.indexOf('v')),
        this.video_url = this.video_url.substring(this.video_url.indexOf('=')+1,this.video_url.length),
        this.$session.set('video_url',this.video_url),
        console.log(123)
      ))
    }
  }
}
</script>

<style>

</style>