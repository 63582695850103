<template src='./template.html'>
  
</template>

<script>
import qs from 'qs'


export default {
    data(){
        return{
            password_eye:false,//密碼顯示
            isDisable: false, //防止連按
            info:null,
            keep_login:false,
            user:{
                account:null,
                password:null
            }
        }
    },
    props:[
        'closeLogin'
    ],
    mounted(){
        this.$refs.input_phone.focus()
        // if(!window.FB){
        window.fbAsyncInit = function() {
        window.FB.init({
            appId      : '439227897531239',
            cookie     : true,
            xfbml      : true,
            version    : 'v12.0'
        });
        window.FB.AppEvents.logPageView();
        console.log('fbAsyncInit')
        };
        // }
    },
    methods:{
        mbCloseMenuActive(){   
            this.closeLogin()
            this.$store.commit('mbCloseMenuActive')
            document.querySelector('body').setAttribute('style', 'overflow:visible;height:auto')
        },
        Login(){
            this.isDisable = true
            setTimeout(() => {
            this.isDisable = false
            }, 1000)
            let vm = this
            console.log('Login')
            if(this.user.account != null & this.user.password != null){
                this.axios.post('/api/member/login',qs.stringify({
                    lang: this.$store.state.lang ,
                    account: this.user.account ,
                    password: this.user.password
                }))
                .then(function(response){
                    console.log(response)
                    if(response.data.status == true){
                        vm.info =response,
                        vm.loadingMemberdata(response)
                        vm.getShopCar()
                        vm.$store.commit('mbCloseMenuActive')
                    }
                    alert(response.data.msg)
                    vm.$store.commit('closeOverlay')
                } )
                .catch(function (error){
                    console.log(error)
                })
            }
            else{
                alert('帳號密碼不可為空')
            }
        },
        loginFb() {
            window.fbAsyncInit()
            let vm = this;
            window.FB.getLoginStatus(function (res) {
                if (res.status === "connected") { 
                    let userID = res["authResponse"]["userID"];
                    console.log("用戶已授權您的App，用戶須先revoke撤除App後才能再重新授權你的App");
                    console.log(`已授權App登入FB 的 userID:${userID}`);
                    vm.getProfile();
                } else if (res.status === 'not_authorized' || res.status === "unknown") {
                    //App未授權或用戶登出FB網站才讓用戶執行登入動作
                    window.FB.login(function (response) {

                        //console.log(response); //debug用
                        if (response.status === 'connected') {
                            //user已登入FB
                            //抓userID
                            let userID = response["authResponse"]["userID"];
                            console.log(`已授權App登入FB 的 userID:${userID}`);
                            vm.getProfile();

                        } else {
                            // user FB取消授權
                            alert("Facebook帳號無法登入");
                        }
                        //"public_profile"可省略，仍然可以取得name、userID
                    }, { scope: 'public_profile,email,user_birthday' }); 
                }
            });
        },
        getProfile() {
            let vm = this
            window.FB.api("/me?fields=name,id,email,birthday,last_name,first_name", function(res) {
                console.log(res)
                if(res.error){
                    console.log(res.error)
                }
                else{
                    vm.axios.post('/api/member/fb_login',qs.stringify({
                        lang: vm.$store.state.lang,
                        id:res.id,
                        first_name:res.first_name,
                        last_name:res.last_name,
                        email:res.email,
                        birthday:res.birthday,
                    }),
                    )
                    .then(response => (
                        console.log(res.id),
                        console.log(res.email),
                        console.log(response),
                        vm.info=response,
                        vm.loadingMemberdata(response),
                        vm.closeLogin()
                    ))
                    .catch(function (error){
                        alert('會員登入失敗')
                        console.log(error)
                    })
                }
            })
        },
        //get shopcar
        getShopCar(){
            let vm = this
            vm.$store.commit('openOverlay')
            vm.axios.post('/api/car/show_car', qs.stringify({
                lang: vm.$store.state.lang,
                isdealer: vm.$session.get('isdealer'),
            }))
            .then(function(response)  {
                    console.log(response)
                if(response.data.car){
                    vm.$session.set('shopcar',response.data.car),
                    vm.$store.commit('shopcarItem',response.data.car.item)
                }
            })
            .catch(err => (
                alert(err)
            ))
        },
        //將資料存入session
        loadingMemberdata(response){
            console.log(response.data)
            let vm = this
            if(vm.keep_login === true){
                this.$store.commit('keepLogin')
                this.$store.commit('memberName',response.data.member_data.name)
                this.$store.commit('memberAccount',response.data.member_data.account)
                this.$store.commit('memberUserId',response.data.member_data.userid)
                this.$store.commit('memberTel',response.data.member_data.tel)
                this.$store.commit('memberNumbering',response.data.member_data.numbering)
                this.$store.commit('memberSex',response.data.member_data.sex)
                this.$store.commit('memberEmail',response.data.member_data.email)
                this.$store.commit('memberBirthday',response.data.member_data.birthday)
                this.$store.commit('memberCity',response.data.member_data.city)
                this.$store.commit('memberTown',response.data.member_data.town)
                this.$store.commit('memberAddress',response.data.member_data.address)
            }
            this.$store.commit('memberLogin')
            this.$session.set('memberLogin',true),
            this.$session.set('memberName',response.data.member_data.name),
            this.$session.set('memberAccount',response.data.member_data.account)
            ,
            this.$session.set('memberId',response.data.member_data.userid)
            ,
            this.$session.set('memberTel',response.data.member_data.tel)
            ,
            this.$session.set('memberNumbering',response.data.member_data.numbering)
            ,
            this.$session.set('memberSex',response.data.member_data.sex)
            ,
            this.$session.set('memberEmail',response.data.member_data.email)
            ,
            this.$session.set('memberBirthday',response.data.member_data.birthday)
            ,
            this.$session.set('memberCity',response.data.member_data.city)
            ,
            this.$session.set('memberTown',response.data.member_data.town)
            ,
            this.$session.set('memberAddress',response.data.member_data.address)
            this.$store.commit('closeLogin')
            window.location.reload()
        },
        //密碼顯示status
        eyeStatus(){
            this.password_eye = !this.password_eye
        }
    }
}
</script>

<style>

</style>