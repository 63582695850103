import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
export default new VueRouter({
  mode: 'history',
  routes :[
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        isDealerEmail: true
      }
    },
    //防止亂導
    {
      path: '*',
      redirect: '/',
    },
    {
      path: '/dealer',
      name: 'Dealer',
      component: () => import(/* webpackChunkName: "Dealer" */ '../views/Dealer.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    {
      path: '/shopmanual',
      name: 'Shopmanual',
      component: () => import(/* webpackChunkName: "Shopmanual" */ '../views/Shopmanual.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    ////會員
    {
      path: '/creatmember',
      name: 'CreatMember',
      component: () => import(/* webpackChunkName: "CreatMember" */ '../views/member/CreatMember.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    {
      path: '/register',
      name: 'register',
      component: () => import(/* webpackChunkName: "register" */ '../views/register.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    {
      path: '/Forget',
      name: 'Forget',
      component: () => import(/* webpackChunkName: "TwForget" */ '../views/member/TwForget.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    {
      path: '/ForgetDealer',
      name: 'ForgetDealer',
      component: () => import(/* webpackChunkName: "TwForget" */ '../views/member/TwForgetDealer.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    {
      path: '/MemberCenter/:id?',
      name: 'MemberCenter',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/member/MemberCenter.vue'),
      meta: {
        requireAuth: true,  // true表示需要登录
      }
    },
    ////購物
    {
      path: '/ShoppingCenter/:fid/:sid/:iid?/:search?/:page?',
      name: 'ShoppingCenter',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/shopping/ShoppingCenter.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    {
      path: '/ShoppingScratch/:scratch',
      name: 'ShoppingScratch',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/shopping/ShoppingScratch.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    {
      path: '/Productview/:mainid',
      name: 'Productview',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/shopping/Productview.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    ///購物車

    {
      path: '/Shopcar',
      name: 'Shopcar',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/shopcar/shopcar.vue'),
      meta: {
        requireAuth: true,  // true表示需要登录
        isDealerEmail: true
      }
    },
    {
      path: '/Finalshopcar/:ordernumber',
      name: 'Finalshopcar',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/shopcar/Finalshopcar.vue'),
      meta: {
        requireAuth: true,  // true表示需要登录
        isDealerEmail: true
      }
    },
    {
      path: '/ReturnProduct/:ordernumber',
      name: 'ReturnProduct',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/shopcar/ReturnProduct.vue'),
      meta: {
        requireAuth: true,  // true表示需要登录
        isDealerEmail: true
      }
    },
    ///news
    {
      path: '/News',
      name: 'News',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/news/New.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    {
      path: '/NewDetail/:id',
      name: 'NewDetail',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/news/NewDetail.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    //客服中心
    {
      path: '/Customerservice',
      name: 'Customerservice',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/Customerservice.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    //最新消息
    {
      path: '/Subview',
      name: 'Subview',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/Subview.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    //常見問題
    {
      path: '/Problem',
      name: 'Problem',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/Problem.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    //隱私政策
    {
      path: '/Privacy',
      name: 'Privacy',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/Privacy.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    //質量方針
    {
      path: '/Quality',
      name: 'Quality',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/Quality.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    //隱私政策
    {
      path: '/Terms',
      name: 'Terms',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/Terms.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    //新增頁面項目
    {
      path: '/AddItem/:mainid',
      name: 'AddItem',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/AddItem.vue'),
      meta: {
        isDealerEmail: true
      }
    },
    //尺寸與材質
    {
      path: '/Size',
      name: 'Size',
      component: () => import(/* webpackChunkName: "MemberCenter" */ '../views/Size.vue'),
      meta: {
        isDealerEmail: true
      }
    },


  ]
})
