import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store/index.js'
import qs from 'qs'
import './assets/application.js'
import VueSession from 'vue-session'



Vue.use(VueSession)
Vue.use(VueAxios, axios);
Vue.use(qs);
Vue.config.productionTip = false





axios.interceptors.request.use(
  config => { //在所有请求头部添加token值
    const token = router.app.$session.get("token")
    if (token) {
      config.headers.token = token;
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
),

// 所有請求response後的function
axios.interceptors.response.use(
  function (response) {
    if (response.data.message == "Token error!") {
      axios.post('/api/index/get_token', qs.stringify({
        account: Math.random().toString(36).substr(2, 8)
      }))
      .then(res => (
        router.app.$session.set('token', res.data.token),
        router.go(0)
        // console.log(this.$session.get('token'))
      ))
    }
    return response
  },
  function(){
    // alert('錯誤!請通知人員' + err)
    router.app.$store.commit("closeOverlay")
  }
),




// router.beforeUpdate((to, from, next) => {
//   console.log(to, from);
//   next();
// });


router.beforeEach((to, from, next) => {
  const isLogin = router.app.$session.get('memberLogin')
  const isEmail = router.app.$session.get('memberEmail')
  const isDealer = router.app.$session.get('isdealer')
  //經銷商 有無email判定
  if(to.matched.some(r => r.meta.isDealerEmail)){
    if (isDealer && !isEmail) {
      next('/MemberCenter')
    }
  }

  //有無會員登入判定
  if (to.matched.some(r => r.meta.requireAuth)) {
    if (isLogin) {
      next();
    }
    else {
      if (to.path !== from){
        alert('請先登入會員'),
        next(from);
        router.app.$store.commit('activeLogin')
      }
      else
        next();
    }
  }
  else {
    next();
  }
});



new Vue({
  router,
  vuetify,
  store,
  qs,
  render: h => h(App)
}).$mount('#app')

